/**
 * @fileoverview
 * @module components/header/AppNotifications
 * @author Braulio Rodriguez <brauliorg@gmail.com>
 * @version 0.1.6
 * @requires react-cssfx-loading
 * @requires @mui/icons-material/GppBad
 */

import { FadingBalls } from "react-cssfx-loading";
import GppBadIcon from '@mui/icons-material/GppBad';

/**
 * Component to display a Loading
 * @param {object} props - The component props.
 * @param {string} props.type
 * @param {string} props.error
 * @param {string} props.loading
 * @returns {JSX.Element} - The Loading component.
 */
const Loading = ({children, type, error, loading}) => {
    if (type === 'flex'){
        return (
            <div className="bounce-loader" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
                <FadingBalls color="#777"/>
            </div>
        );
    } else if (type === 'appnotifications'){
        return (
            <>
                {error ? (
                    <ul className="notifications">
                        <li>
                            <div style={{top: 10}}>ERROR</div>
                        </li>
                    </ul>
                ) : (
                    loading ? (
                        <ul className="notifications">
                            <li>
                                <div style={{top: 10}}><FadingBalls color="#777"/></div>
                            </li>
                        </ul>
                    ) : (
                        children
                    )
                )}
            </>
        )
    } else {
        return (
            <>
                {error ? (
                    <p><GppBadIcon /> Se ha producido un error!</p>
                ) : (
                    loading ? (
                        <p>Cargando...<FadingBalls color="#777"/></p>
                    ) : (
                        children
                    )
                )}
            </>
        );
    };
};

export default Loading;