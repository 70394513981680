/**
 * @fileoverview
 * This code exports a function called reportWebVitals which is used to measure the performance of a React application.
 * This module facilitates the integration of 'web-vitals' to measure the performance of a React application and provides a way to record these metrics for analysis and continuous improvement.
 * @author Braulio Rodriguez <brauliorg@gmail.com>
 * @version 0.1.6
 */

/**
 * Function to report web vitals for performance monitoring.
 * @param {Function} onPerfEntry - Callback function to handle performance entries.
 * @returns {void}
 */
const reportWebVitals = (onPerfEntry) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
            getCLS(onPerfEntry);
            getFID(onPerfEntry);
            getFCP(onPerfEntry);
            getLCP(onPerfEntry);
            getTTFB(onPerfEntry);
        });
    };
};

export default reportWebVitals;
