/**
 * @fileoverview
 * Initializer
 * This code starts the React application, makes a request for internationalisation resources (i18n), and renders the main application or an error page depending on the result of the request. It also configures performance metrics using reportWebVitals.
 * @author Braulio Rodriguez <brauliorg@gmail.com>
 * @version 0.1.6
 * @requires React
 * @requires react-dom/client
 * @requires ./App
 * @requires ./components/pages/AppError
 * @requires ./reportWebVitals
 * @requires react-redux
 * @requires ./store
 * @requires react-i18next
 * @requires i18next
 * @requires ./language/es/global.json
 * @requires ./language/en/global.json
 * @requires axios
 * @requires ./constants/global
 * @requires ./components/theme/index
 */

import React, { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AppError } from './components/pages/index';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import global_es from "./language/es/global.json";
import global_en from "./language/en/global.json";
import axios from "axios";
import { Url } from './constants/global';
import { Loading } from './components/theme/index';

const postURL = Url + "getlanguagei18";
const key = { keys: 'website' };
const config = {
    headers: {
        'Content-Type': 'application/json'
    }
};

/**
 * Main application entry point.
 * @returns {Promise<void>} A Promise that resolves when the application is initialized.
 */
const initApp = async () => {
    try {
        const { data } = await axios.post(postURL, key, config);
        if(process.env.REACT_APP_SHOW_CONSOLE === 'true'){
            console.log('%c [Conexys] ', 'color: #55ff00', 'Request '+ postURL)
        };
        i18next.init({
            interpolation: { escapeValue: false },
            lng: "en",
            resources: data.data
        });
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <StrictMode>
                <Suspense fallback={<Loading type='flex'/>}>
                    <Provider store={store}>
                        <I18nextProvider i18n={i18next}>
                            <App />
                        </I18nextProvider>
                    </Provider>
                </Suspense>
            </StrictMode>
        );

    } catch (err) {
        i18next.init({
            interpolation: { escapeValue: false },
            lng: "en",
            resources: {
                es:{
                    global: global_es
                },
                en:{
                    global: global_en
                },
            },
        });
        if(process.env.REACT_APP_SHOW_CONSOLE === 'true'){
            console.log('%c [Conexys] ', 'color: #fc0000', 'Error REST API')
        };
        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <StrictMode>
                <Suspense fallback={<Loading type='flex'/>}>
                    <Provider store={store}>
                        <I18nextProvider i18n={i18next}>
                            <AppError error={err}/>
                        </I18nextProvider>
                    </Provider>
                </Suspense>
            </StrictMode>
        );
    };
};

// Initialize the application
initApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
reportWebVitals();
